import { isNumber, isFinite } from 'lodash';
import { HighlightBindingness } from '.';
import { nbsp } from '../constants';
import { ShowDigits } from '../utils';
import { formatCurrency } from './calcUtils';
import { CalculationMode } from './constants';

/**
 * Checks if a given value is a discount.
 *
 * A discount is considered if the `discountedValue` is different than the `priceBeforeDiscount`
 * and both values are numbers.
 *
 * @param {Object} inputProps - The value to check.
 * @param {boolean} inputProps.customPrice - Whether the value is a custom price.
 * @param {number} inputProps.discountedValue - The discounted value.
 * @param {number} inputProps.priceBeforeDiscount - The price before discount.
 * @param {number} inputProps.discountedCustomPrice - The price before discount.
 * @returns {boolean} Whether the value is a discount.
 */
const checkIsDiscount = (value) => {
  if (value) {
    const isCustomPrice = isFinite(value.customPrice);
    const priceBeforeDiscount = value[isCustomPrice ? 'customPrice' : 'priceBeforeDiscount'];
    const discountedValue = value[isCustomPrice ? 'discountedCustomPrice' : 'discountedValue'];
    return discountedValue !== priceBeforeDiscount && isNumber(discountedValue) && isNumber(priceBeforeDiscount);
  }
  return false;
};

/**
 * Takes a value and returns 'show' or 'hide' depending on whether
 * it is a valid string input for show/hide.
 *
 * @param {string} value
 * @returns {string}
 */
const parseShowMinMax = (value) => {
  return typeof value !== 'string' || (value !== 'show' && value !== 'hide') ? 'hide' : value;
};

/**
 * Returns an object with two properties: showMin and showMax.
 * The value of each property is either 'show' or 'hide'.
 * 'show' is returned if the corresponding price is a positive number.
 * 'hide' is returned otherwise.
 *
 * @param {number} minPrice
 * @param {number} maxPrice
 * @returns {object}
 */
export const getShowMinMaxValue = (minPrice, maxPrice) => {
  return {
    showMin: Number.isFinite(minPrice) && minPrice > 0 ? 'show' : 'hide',
    showMax: Number.isFinite(maxPrice) && maxPrice > 0 ? 'show' : 'hide',
  };
};

/**
 * @typedef {Object} PriceFormatterVariables
 * @property {string} [customPrice] - The custom price value formatted as currency string.
 * @property {string} [discount] - The discount value formatted as currency string or as a percentage.
 * @property {string} [discountedCustomPrice] - The discounted custom price value formatted as currency string.
 * @property {string} [discountedValue] - The discounted value formatted as currency string.
 * @property {number} [discountedValueRaw] - The discounted value as a number.
 * @property {boolean} [hideInterval] - A boolean indicating whether the payment interval should be hidden.
 * @property {boolean} [isDiscounted] - A boolean indicating whether there is a discount.
 * @property {string} [paymentInterval] - The payment interval as a string.
 * @property {string} [priceBeforeDiscount] - The price before discount formatted as currency string.
 * @property {number} [priceBeforeDiscountRaw] - The price before discount as a number.
 * @property {number} [highlightBindingness] - A number indicating the bindingness of the price.
 * @property {string} [minPrice] - The minimum price formatted as currency string.
 * @property {string} [maxPrice] - The maximum price formatted as currency string.
 * @property {string} [showMin] - A string indicating whether the minimum price should be shown.
 * @property {string} [showMax] - A string indicating whether the maximum price should be shown.
 */

/**
 * @typedef {Object} PriceFormatterResult
 * @property {string} code - A string key for a translation in the messages.json file.
 * @property {PriceFormatterVariables} variables - An object with variables that are used in the translation.
 */

/**
 * Formats price information based on input properties and returns a code and variables for translation.
 *
 * @param {Object} inputProperties - The input properties for price formatting.
 * @param {string} inputProperties.paymentInterval - The payment interval.
 * @param {Object} inputProperties.discount - The discount information.
 * @param {boolean} inputProperties.hideInterval - Whether to hide the payment interval.
 * @param {boolean} [inputProperties.showDiscounts=true] - Whether to show discounts.
 * @param {boolean} inputProperties.currentFeeTypeMonthly - Whether the current fee type is monthly.
 * @param {ShowDigits} [inputProperties.showDigits=ShowDigits.avoid] - How to display digits in the price.
 * @param {boolean} [inputProperties.showCalculatedPrice=true] - Whether to show the calculated price.
 * @param {Object} inputProperties.value - The price value.
 * @param {string} [inputProperties.calculationMode=CalculationMode.VALUE] - The calculation mode for the price.
 * @param {HighlightBindingness} [inputProperties.highlightBindingness=HighlightBindingness.NO_HIGHLIGHT] - How to highlight the bindingness of the price.
 * @param {boolean} [inputProperties.isTotalPrice=false] - Whether this is a total price.
 * @param {number} [inputProperties.minPrice=0] - The minimum price.
 * @param {number} [inputProperties.maxPrice=0] - The maximum price.
 * @param {string} [inputProperties.showMin='hide'] - Whether and how to show the minimum price.
 * @param {string} [inputProperties.showMax='hide'] - Whether and how to show the maximum price.
 *
 * @returns {PriceFormatterResult} An object containing a code for translation and variables for formatting.
 */
export const priceInfoAsText = ({ paymentInterval, discount, hideInterval, showDiscounts = true, currentFeeTypeMonthly, showDigits = ShowDigits.avoid, showCalculatedPrice, value, calculationMode = CalculationMode.VALUE, highlightBindingness = HighlightBindingness.NO_HIGHLIGHT, isTotalPrice, minPrice = 0, maxPrice = 0, showMin = 'hide', showMax = 'hide' }) => {
  const formatCurrencyOptions = { showDigits };

  if (calculationMode === CalculationMode.ON_ACTUAL_COST)
    return {
      code: 'code_on_actual_cost',
      variables: {
        paymentInterval,
        calculationMode,
        minPrice: formatCurrency(minPrice, formatCurrencyOptions),
        maxPrice: formatCurrency(maxPrice, formatCurrencyOptions),
        showMin: parseShowMinMax(showMin),
        showMax: parseShowMinMax(showMax),
      },
    };

  if (currentFeeTypeMonthly && (paymentInterval === 'monthly' || paymentInterval === 'yearly'))
    return {
      code: `fixedMonthlyFeeHint${discount?.value ? '_discount' : ''}`,
      variables: {
        paymentInterval,
        highlightBindingness: calculationMode === CalculationMode.ON_ACTUAL_COST ? HighlightBindingness.NO_HIGHLIGHT : highlightBindingness,
        minPrice: formatCurrency(minPrice, formatCurrencyOptions),
        maxPrice: formatCurrency(maxPrice, formatCurrencyOptions),
        showMin: parseShowMinMax(showMin),
        showMax: parseShowMinMax(showMax),
      },
    };

  const { customPrice, discountedCustomPrice, discountedValue, priceBeforeDiscount } = value ?? {};
  const formattedDiscount = discount?.value && (discount?.type === 'percent' ? `${discount?.value}${nbsp}%` : formatCurrency(discount?.value));

  const isCustomPrice = isFinite(customPrice);
  if (!isTotalPrice && !isCustomPrice && !discountedValue)
    return {
      code: `free_service`,
      variables: {
        highlightBindingness,
        paymentInterval,
      },
    };

  const isDiscounted = checkIsDiscount(value);
  const variables = {
    customPrice: formatCurrency(customPrice, formatCurrencyOptions),
    discount: formattedDiscount,
    discountedCustomPrice: formatCurrency(discountedCustomPrice, formatCurrencyOptions),
    discountedValue: formatCurrency(discountedValue, formatCurrencyOptions),
    discountedValueRaw: discountedValue,
    hideInterval: !!hideInterval,
    isDiscounted,
    paymentInterval: hideInterval ? '' : paymentInterval,
    priceBeforeDiscount: formatCurrency(priceBeforeDiscount, formatCurrencyOptions),
    priceBeforeDiscountRaw: priceBeforeDiscount,
    highlightBindingness,
    minPrice: formatCurrency(minPrice, formatCurrencyOptions),
    maxPrice: formatCurrency(maxPrice, formatCurrencyOptions),
    showMin: parseShowMinMax(showMin),
    showMax: parseShowMinMax(showMax),
  };

  let code = 'code';
  /*
  When is it added?
  - when there is a customPrice (in shopping cart => category => item)
  */
  if (isCustomPrice) code += '_customPrice';
  /*
  When is it added?
  - when the showCalculatedPrice switch is on (where customPrice is set in shopping cart => category => item)
  */
  if (isCustomPrice && showCalculatedPrice) code += '_showCalculatedPrice';
  /*
  When is it added?
  - when a discount is selected for this field
  Ignore:
  - if showDiscounts (shopping cart view options button) is false.
  */
  if (showDiscounts && isDiscounted) code += '_discount';
  /*
  When is it added?
  - when discounts have not value (calculate shopping cart total)
  Ignore:
  - when there is a customPrice
  - if showDiscounts or isDiscounted are false.
  */
  if (!isCustomPrice && showDiscounts && isDiscounted && !discount?.value) code += '_noDiscountValue';

  return { code, variables };
};
